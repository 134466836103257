<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Verkoopprognose</h1>
                        <a
                            class="page-header-button"
                            @click="mode = (mode === 'types' ? 'articles' : 'types');
                            groupIds = []; getGroupData()"
                        >
                                Toon per {{ mode === 'types' ? 'verkoopartikel' : 'artikelgroep' }}
                        </a>
                        <Export
                            :from="f"
                            :till="t"
                            :fabButton="true"
                            :mode="mode"
                            :data="customerIds.length ? data : []"
                            :weeks="weeks"
                        />
                    </div>
                    <div class="page-header-settings" style="border-bottom: 0; margin-bottom: 0;">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-2 col-xl-3">
                                <div class="datepicker-styling">
                                    <span class="styled-label">Van</span>
                                    <DatePicker @input="dateChanged()" v-model="f" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-2 col-xl-3">
                                <div class="datepicker-styling">
                                    <span class="styled-label">Tot en met</span>
                                    <DatePicker @input="dateChanged()" v-model="t" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-3">
                                <span class="styled-label">Klant</span>

                                <MultiSelect
                                    :select-all-option="true"
                                    v-model="secondCustomerIds"
                                    :options="customers.all().map(c => ({label: c.name, value: c.id}))"
                                    placeholder="Maak een keuze"
                                    :include-search="true"
                                    @update:modelValue="customerIdsChanged"
                                ></MultiSelect>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 col-xl-3">
                                <span class="styled-label">Artikel</span>

                                <MultiSelect
                                    v-model="groupIds"
                                    :options="(mode === 'types' ? types : usedArticles).all().map(c => ({
                                        label: (mode === 'types' ? c.translate('name') : c.name),
                                        value: c.id
                                    }))"
                                    placeholder="Maak een keuze"
                                ></MultiSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sales-forecast-holder">
            <table class="styled-table sales-forecast" :style="loader ? 'width: 100%' : `width: ${560 + (weeks.length * 120)}px;`">
                <thead v-if="! loader" class="styled-table-header">
                    <tr>
                        <th><div>Klant</div></th>
                        <th>
                            <div>
                                {{ mode === 'types' ? 'Type' : 'Artikel' }}
                            </div>
                        </th>
                        <th v-for="(week, index) of weeks" :key="index" :class="{current: week.current.isSame(today, 'isoWeek')}">
                            <div class="week-number">{{ week.current.isoWeek() }}</div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="loader">
                    <tr>
                        <td col-span="100" style="text-align:center;">
                            <Loader />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="customerIds.length <= 0 || customers.length <= 0">
                    <tr>
                        <td col-span="100" style="text-align:center;">
                            <div class="no-data-placeholder"><p>Selecteer een klant</p></div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="! loader && customerIds.length >= 0" class="styled-table-body" v-for="(customer, index) of data" :key="index">
                    <tr v-for="(article, index2) of customer.groups" :key="'a' + index2">
                        <td style="border-bottom:0; background-color: white;">
                            <div style="width:200px; text-align:left">
                                {{ index2 === 0 ? customer.name : ' ' }}
                            </div>
                        </td>
                        <td style="background-color: white;">
                            <div :style="`width:${mode === 'types' ? '150' : '360'}px; text-align:left;`">
                                {{ article.name }}
                            </div>
                        </td>
                        <td
                            v-for="(week, weekIndex) in weeks"
                            :key="'w' + weekIndex"
                            @click="mode === 'types' ? '' : openCreateEditSaleIgnoreModal(article, customer, week.current)"
                            :class="[(week.current.isBefore(today, 'isoWeek') ? getDiffClass(article, weekIndex) : null)]"
                        >
                            <div class="week-content">

                                <span v-wbtooltip="`Actie: ${article.weeklyData[weekIndex].saleIgnores}`" class="ignore-amount" v-if="article.weeklyData[weekIndex].saleIgnores" >
                                    <Icon name="exclamation-circle" type="solid" />
                                </span>

                                <!--  FUTURE  -->
                                <span
                                    style="color: silver"
                                    v-if="week.current.isAfter(today, 'isoWeek')"
                                    v-wbtooltip="() => getTooltip(week.current, article.id, customer.id)"
                                >
                                    <span>{{ article.weeklyData[weekIndex].amount }}</span>
                                </span>

                                <!--  PAST AND PRESENT  -->
                                <span v-else>
                                    <span v-wbtooltip="() => getTooltip(week.current, article.id, customer.id)">
                                        {{ article.weeklyData[weekIndex].currentBunches !== 0 ? article.weeklyData[weekIndex].currentBunches : '-' }}
                                    </span>
                                </span>

                                <!-- BLUE SALE FORECAST -->
                                <a v-if="mode === 'types'" class="add-sale-forecast" :class="{'has-sale-forecast': article.weeklyData[weekIndex].saleForecast}">
                                    <span style="position: static;"
                                        v-wbtooltip="
                                            article.weeklyData[weekIndex].saleForecast !== null
                                            ?  `${article.weeklyData[weekIndex].saleForecast.user.name()}<br/> ${article.weeklyData[weekIndex].saleForecast.createdAt.format('DD-MM-YYYY HH:mm')}`
                                            : null
                                        "
                                        @click="openCreateEditSaleForecastModal(customer, article, week.current)"
                                    >{{
                                        article.weeklyData[weekIndex].saleForecast
                                        ? article.weeklyData[weekIndex].saleForecast.amount
                                        : '+'
                                    }}</span>
                                </a>

                                <!-- ADD 0 BLUE SALE FORECAST -->
                                <a v-if="mode === 'types' && ! article.weeklyData[weekIndex].saleForecast" class="add-sale-forecast zero">
                                    <span style="position: static;"
                                        @click="storeSaleForecastOfZero(customer, article, week.current)"
                                    >+0</span>
                                </a>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <div class="widget-holder " style="width:100%">
            <div class="no-data-placeholder"><p>Selecteer een klant</p></div>
        </div> -->

        <CreateEditSaleIgnore
            @close="createEditSaleIgnoreModal = false"
            :data="createEditSaleIgnoreModal"
            @stored="saleIgnoreStored"
        />

        <CreateEditSaleForecast
            @close="createEditSaleForecastModal = false"
            :data="createEditSaleForecastModal"
            @stored="(saleForecast) => {
                saleForecasts.push(saleForecast);
                createEditSaleForecastModal = false;
            }"
            @editted="(saleForecast) => {
                createEditSaleForecastModal = false
                saleForecasts = saleForecasts.map(sf => (sf.id === saleForecast.id ? saleForecast : sf))
            }"
        />

    </div>

</template>

<script setup>
    import { computed, ref, nextTick, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import customerService from '@/services/http/customer-service';
    import articleService from '@/services/http/article-service';
    import typeService from '@/services/http/type-service';
    import { collect } from "collect.js";
    import useSaleForecastCalculations from './../useSaleForecastCalculations';
    import saleIgnoreService from '@/services/http/sale-ignore-service';
    import saleForecastService from '@/services/http/sale-forecast-service';
    import orderService from '@/services/http/order-service';
    import Export from './_Export.vue';
    import Loader from '@/components/Loader.vue';

    // Components
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import CreateEditSaleIgnore from './_CreateEditSaleIgnore.vue';
    import CreateEditSaleForecast from './_CreateEditSaleForecast.vue';
    import moment from 'moment';

    const getDiffClass = function(article, weekIndex) {
        const bunches = article.weeklyData[weekIndex].currentBunches;
        const saleForecastAmount = article.weeklyData[weekIndex].saleForecast
            ? article.weeklyData[weekIndex].saleForecast.amount
            : null;

        if (saleForecastAmount === null) {
            return null;
        }

        if (
            saleForecastAmount == 0 && bunches != 0
            || saleForecastAmount != 0 && bunches == 0
        ) {
            return 'missing-data';
        }

        const high = saleForecastAmount > bunches ? saleForecastAmount : bunches;
        const low = saleForecastAmount > bunches ? bunches : saleForecastAmount;
        const amount = Math.ceil(((high-low)/saleForecastAmount)*10);

        return ('diff-' + (amount > 9 ? 9 : amount));
    }

    const emit = defineEmits(['breadcrumbs']);

    const {
        f, t, today,
        weeks, betweens,
        setDates
    } = useSaleForecastCalculations();

    const query = useRoute().query;

    const mode = ref('typeId' in query ? 'types' : 'articles');

    const customers = ref(collect());
    const customerIds = ref([]);
    const secondCustomerIds = ref([]);

    const articles = ref(collect());
    const types = ref(collect());
    const groupIds = ref('typeId' in query ? [parseInt(query.typeId)] : []);

    const saleIgnores = ref(collect());
    const createEditSaleIgnoreModal = ref(false)

    const saleForecasts = ref(collect());
    const createEditSaleForecastModal = ref(false);

    const loader = ref(false);

    const getData = function() {
        loader.value = true;
        nextTick();

        return Promise.all([
            getGroupData(false),
            getArticles(),
            getSaleIgnores(),
            getSaleForecasts(),
            getCustomers()
        ]).then(() => {
            loader.value = false;
        })
    }

    const getGroupData = function(setLoader = true) {
        if (setLoader) {
            loader.value = true;
        }
        return Promise.all([
            getSaleHistory(),
            getGroupedSale()
        ]).then(() => {
            if (setLoader) {
                loader.value = false;
            }
        })
    }

    const customerIdsChanged = function() {
        loader.value = true;
        nextTick();
        setTimeout(() => {
            customerIds.value = secondCustomerIds.value;
            loader.value = false;
        }, 1);
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Sale history
    // ---------------------------------------------------------------------------------------------------------------------

    const saleHistory = ref(collect());
    const getSaleHistory = function() {
        return orderService.getSaleHistory(moment(f.value), moment(t.value), [
            'week', (mode.value === 'types' ? 'type_id' : 'article_id'), 'customer_id'
        ], ['average_sold']).then((results) => {
            saleHistory.value = collect(results).groupBy('customer_id');
        });
    }

    const groupedSale = ref(collect());
    const getGroupedSale = function() {
        return orderService.getGroupedSale(moment(f.value), moment(t.value), [
            'week', (mode.value === 'types' ? 'type_id' : 'article_id'), 'customer_id'
        ], ['amount'])
        .then((results) => {
            groupedSale.value = collect(results).groupBy('customer_id');
        });
    }

    const currentTooltip = ref({week: null, articleId: null, customerId: null, tooltip: null});
    const getTooltip = async function(date, articleId, customerId) {
        if (
            currentTooltip.value.week === date.isoWeek()
            && currentTooltip.value.articleId === articleId
            && currentTooltip.value.customerId === customerId
        ) {
            return currentTooltip.value.tooltip;
        }

        currentTooltip.value = {week: date.isoWeek(), articleId: articleId, customerId: customerId, tooltip: null};

        return orderService.getSaleHistory(
            moment(date.clone().startOf('week')),
            moment(date.clone().endOf('week')), [
                'year', 'week', (mode.value === 'types' ? 'type_id' : 'article_id'), 'customer_id'
            ],
            ['amount'],
            {'customer_id': customerId, [(mode.value === 'types' ? 'type_id' : 'article_id')]: articleId}
        ).then((results) => {
            const lastYear = date.clone().subtract(1, 'year');
            const twoYearAgo = date.clone().subtract(2, 'year');
            const threeYearAgo = date.clone().subtract(3, 'year');

            const lastYearResult = results.find(r => r.year == lastYear.isoWeekYear());
            const twoYearAgoResult = results.find(r => r.year == twoYearAgo.isoWeekYear());
            const threeYearAgoResult = results.find(r => r.year == threeYearAgo.isoWeekYear());

            return currentTooltip.value.tooltip =
                lastYear.isoWeekYear() + ': ' + (lastYearResult ? lastYearResult.amount : 0)
                + '<br/>' + twoYearAgo.isoWeekYear() +': ' + (twoYearAgoResult ? twoYearAgoResult.amount : 0)
                + '<br/>' + threeYearAgo.isoWeekYear() +': ' + (threeYearAgoResult ? threeYearAgoResult.amount : 0)
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // SaleForecasts
    // ---------------------------------------------------------------------------------------------------------------------
    const openCreateEditSaleForecastModal = function(customer, type, date) {
        createEditSaleForecastModal.value = {
            type: type,
            date: date,
            customer: customer
        }
    }

    const getSaleForecasts = function() {
        return saleForecastService.get({
            filters: {betweens: betweens.value},
            include: ['user']
        }).then(saleForecastModels => {
            saleForecasts.value = saleForecastModels;
        });
    }

    const storeSaleForecastOfZero = function(customer, type, date) {
        return saleForecastService.create({
            customer_id: customer.id,
            type_id: type.id,
            at: date.format('YYYY-MM-DD'),
            amount: 0
        }, {include: ['user']}).then((saleForecast) => {
            saleForecasts.value.push(saleForecast);
        });
    }

    const startLoader = function() {
        loader.value = true;
        nextTick();
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Calculate table data
    // ---------------------------------------------------------------------------------------------------------------------

    const groupedData = computed(() => {
        if (! saleIgnores.value) {
            return [];
        }

        const groupedData = [];

        const filteredCustomers = (
            customerIds.value.length
            ? customers.value.whereIn('id', customerIds.value)
            : customers.value
        );

        for (const customer of filteredCustomers) {
            let articleIds = collect([
                ...collect(saleHistory.value.get(customer.id)).pluck(mode.value === 'types' ? 'type_id' : 'article_id').all(),
                ...collect(groupedSale.value.get(customer.id)).pluck(mode.value === 'types' ? 'type_id' : 'article_id').all(),
            ]).unique().all();

            const group = {
                name: customer.name,
                id: customer.id,
                historyData: saleHistory.value.get(customer.id) ?? collect(),
                presentData: groupedSale.value.get(customer.id) ?? collect(),
                articles: usedArticles.value.filter(a => articleIds.includes(a.id)),
                saleIgnores: saleIgnores.value.where('customerId', customer.id),
                saleForecasts: saleForecasts.value.where('customerId', customer.id)
            }

            if (groupIds.value.length > 0) {
                group['articles'] = group['articles'].whereIn('id', groupIds.value);
                group['saleIgnores'] = group['saleIgnores'].whereIn('article.' + (mode.value === 'types' ? 'typeId' : 'id'), groupIds.value);
                group['saleForecasts'] = group['saleForecasts'].whereIn('typeId', groupIds.value);
                group['historyData'] = group['historyData'].whereIn((mode.value === 'types' ? 'type_id' : 'article_id'), groupIds.value);
                group['presentData'] = group['presentData'].whereIn((mode.value === 'types' ? 'type_id' : 'article_id'), groupIds.value);
            }

            group['groups'] = (mode.value === 'types' ? types.value.whereIn('id', group.articles.pluck('id').unique().all()) : group.articles);

            groupedData.push(group);
        }

        return groupedData;
    })

    const data = computed(() => {
        let data = [];
        if (! saleIgnores.value) {
            return [];
        }

        for (let customer of groupedData.value) {
            let customerData = {
                name: customer.name,
                id: customer.id,
                groups: []
            };

            const customerArticleData = customer.historyData.groupBy(mode.value === 'types' ? 'type_id' : 'article_id');
            const customerPresentData = customer.presentData.groupBy(mode.value === 'types' ? 'type_id' : 'article_id');

            for (let group of customer.groups) {
                let groupData = {
                    id: group.id,
                    name: mode.value === 'types' ? group.translate('name') : group.name,
                    weeklyData: [],
                };

                let articles = (mode.value === 'types' ? customer.articles.firstWhere('id', group.id) : group);
                groupData['saleIgnores'] = customer.saleIgnores.where((mode.value === 'types' ? 'article.typeId' : 'articleId'), articles.id)
                groupData['saleForecasts'] = customer.saleForecasts.where('typeId', (mode.value === 'types' ? group.id : 0));

                const articleData = customerArticleData.get(articles.id);
                const presentArticleData = customerPresentData.get(articles.id);
                let lastBunches = 0;
                for (let week of weeks.value) {

                    const saleForecast = groupData['saleForecasts'].last(sf => sf.at.isSame(week.current, 'isoWeek'));
                    const weekNr = week.current.isoWeek().toString().padStart(2, '0')
                    const amount = articleData ? articleData.firstWhere('week', weekNr) : null;

                    let weekData = {
                        'gains': null,
                        'saleIgnores': groupData['saleIgnores'].filter(si => si.at.isSame(week.current, 'isoWeek')).sum('amount'),
                        'saleForecast': (saleForecast ? saleForecast : null),
                        'amount': amount ? Math.round(amount.average_sold) : null
                    };

                    if (! week.current.isAfter(today, 'isoWeek')) {
                        weekData['currentBunches'] = getBunches(presentArticleData ? presentArticleData.firstWhere('week', week.current.isoWeek().toString()) : null, articles);
                        lastBunches = weekData['currentBunches'];
                    }
                    groupData.weeklyData.push(weekData);
                }
                customerData.groups.push(groupData);
            }

            data.push(customerData);
        }

        return data;
    });

    const getBunches = function(data, articles) {
        return data ? Math.round(data.amount) : 0;
    }


    // ---------------------------------------------------------------------------------------------------------------------
    // Sale ignores
    // ---------------------------------------------------------------------------------------------------------------------
    const openCreateEditSaleIgnoreModal = function(article, customer, date, amount = 0) {
        /* createEditSaleIgnoreModal.value = {
            article: article,
            customer: customer,
            date: date,
            amount: amount
        }; */
    }

    const saleIgnoreStored = function() {
        getSaleIgnores();
    }

    const getSaleIgnores = function() {
        saleIgnores.value = false;
        return saleIgnoreService.get({
            filters: {
                betweens: betweens.value
            },
            include: ['article']
        }).then(saleIgnoreModels => {
            saleIgnores.value = saleIgnoreModels;
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Dates
    // ---------------------------------------------------------------------------------------------------------------------
    const dateChanged = function(reset = false) {
        if (
            moment(f.value, 'YYYY-MM-DD').isBefore(today.value.clone().subtract(10, 'year'))
            || moment(t.value, 'YYYY-MM-DD').isBefore(today.value.clone().subtract(10, 'year'))
        ) {
            return;
        }
        setDates();

        customers.value = collect();
        if (reset) {
            groupIds.value = [];
        }

        getData();
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Customers
    // ---------------------------------------------------------------------------------------------------------------------
    const getPrognoseCustomers = function() {
        customerService.get({filters: {
            prognose_type_id: query.typeId,
            prognose_from: query.from,
            prognose_till: query.till
        }}).then(customers => {
            customerIds.value = customers.pluck('id').all();
        })
    }
    if ('typeId' in query) {
        getPrognoseCustomers()
    }

    const getCustomers = function()
    {
        return customerService.get({
            filters: {
                betweens: betweens.value,
                order_by: 'name'
            },
        }).then((customerModels) => {
            customers.value = customerModels;
        });
    }


    // ---------------------------------------------------------------------------------------------------------------------
    // Articles / Types
    // ---------------------------------------------------------------------------------------------------------------------

    const getArticles = function()
    {
        return articleService.get({filters: {
            betweens: betweens.value
        }}).then(articleModels => {
            articles.value = articleModels.sortBy('name')
        });
    }

    const usedArticles = computed(() => {
        let ids = [];

        customers.value.whereIn('id', customerIds.value).each(c => {
            ids = ids
                .concat(
                    collect(saleHistory.value.get(c.id))
                    .pluck(mode.value === 'types' ? 'type_id' : 'article_id')
                    .all()
                ).concat(
                    collect(groupedSale.value.get(c.id))
                    .pluck(mode.value === 'types' ? 'type_id' : 'article_id')
                    .all()
                )
        });

        ids = collect(ids).unique();
        return mode.value === 'types'
            ? types.value.filter((t) => ids.all().includes(t.id))
            : articles.value.filter((a) => ids.all().includes(a.id));
    });

    const getTypes = function()
    {
        return typeService.get().then(typeModels => {
            types.value = typeModels;
        });
    }

    Promise.all([
        getTypes()
    ]).then(() => {
        dateChanged();
    });

    emit('breadcrumbs', [
        {to: `/sowing-planning`, label: 'Zaaiplanning'},
        {label: 'Verkoopprognose'}
    ]);
</script>
